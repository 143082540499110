import './index.scss';

import * as firebase from 'firebase/app';
import * as serviceWorker from './serviceWorker';

import App from './components/app/App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './store/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const firebaseConfig = {
  apiKey: 'AIzaSyAR2C5eRco8jGUmA1CR9DPZzdsOo0lszKo',
  // authDomain: 'draft-craft.firebaseapp.com',
  // databaseURL: 'https://draft-craft.firebaseio.com',
  projectId: 'parx-app',
  // storageBucket: 'draft-craft.appspot.com',
  // messagingSenderId: '806218557235',
  appId: '1:882518147650:web:df23862c6042a3e57563db',
  // measurementId: 'G-BR288VX1HR',
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
