import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import background from './background.png';
import cabin from './parx-cabin.jpg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import logo from './parx-logo-orange-transparent.png';
import styles from './App.module.scss';

function App() {
  return (
    <>
      <div className={styles.App} style={{ backgroundImage: `url(${background})` }}>
        <div className={styles.main}>
          <img src={logo} className={styles.logo} alt="Parx" />
          <div className={styles.banner}>
            <h1>Product development and services</h1>
          </div>
        </div>
        <div className={styles.down}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
      <div className={styles.body__wrapper}>
        <div className={styles.body}>
          <div>
            <h1>Our Goal</h1>
            <p>
              To provide capital, infrastructure, and services to emerging and decommoditized markets and identify inefficiencies in existing markets
              with underrepresented demographics using digital and technological disruption via automation, cloud infrastructure, supply chain management, globalization, and virtualization.
            </p>
            <button className={styles.button__main}>Learn More</button>
          </div>
          <img src={cabin} className={styles.cabin} alt='Our Goal' />
        </div>
      </div>
      <div className={styles.footer}>
        <p>
          <small>Content Copyright © 2019 - {new Date().getFullYear()}</small><br />
          Park City Concepts, LLC (dba Parx)
        </p>
      </div>
    </>
  );
}

export default App;
/*
- Entrepreneurs
- Scalabe
- R&D
- Investing
- Product
- Engineering
- Development
- Private Equity
- Retail
- Diverse Demographics
- White label
- Disruption
- Lab
- Think tank
- Passion
- Arbitrage
- Geographic Maekets
- Market Segments and Demographics
- Bespoke Solutions
- Emerging and Decommoditized Markets
- Our goal: To provide capital, infrastructure, and services to emerging and decommoditized markets and identify inefficiencies in existing markets
  with underrepresented demographics using digital and technological distruption via automation, cloud infrastructure, supply chain management, globalization, and virtualization.
- Identify inefficiencies in existing products and markets that entrepreneurs and freelancers are passionate about that
 are ripe for digital and technological distruption via automation, cloud infrastructure, supply chain management, globalization, and virtualization.
*/

/*
- Commoditized
- Commodity solutions
- Providing a platform to enable commodity market solutions at scale.
*/